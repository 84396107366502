import { useEffect, useState } from "react";
import { useEthNetwork } from "./NetworkConnector";
import { PopularPixels, PopularPixels__factory } from "./typechain";
import { PUBLIC_PIIXELS_ADDRESS } from "./util/Constants";

import HeroSection from "./sections/HeroSection";
import StatsSection from "./sections/StatsSection";
import EditorSection from "./sections/EditorSection";
import { BigNumber } from "ethers";
import HistorySection from "./sections/HistorySection";

export default function DynamicContent() {

  const [active, library]                     = useEthNetwork();
  const [art, setArt]                         = useState<string|null>(null);
  const [ownershipMap, setOwnershipMap]       = useState<[BigNumber, string, string | null][]>([]);
  const [remainingBlocks, setRemainingBlocks] = useState<BigNumber|null>(null);
  const [revisionCount, setRevisionCount]     = useState<BigNumber|null>(null);
  const [tilePrice, setTilePrice]             = useState<BigNumber|null>(null);


  useEffect(() => {
    async function retrieveArt() {
      console.log("Retrieving art...");
      const contract : PopularPixels = PopularPixels__factory.connect(PUBLIC_PIIXELS_ADDRESS, library);

      const svgPromise             = contract.getEncodedSvgUri();
      const ownershipMapPromise    = contract.getOwnersMap();
      const remainingBlocksPromise = contract.getAvailablePixelBlocksCount();
      const revisionCountPromise   = contract.getRevisionCount();
      const tilePricePromise       = contract.getPrice();

      const [svg, ownershipMap, remainingBlocks, revisionCount, tilePrice] = 
          await Promise.all([svgPromise, ownershipMapPromise, 
                             remainingBlocksPromise, revisionCountPromise, tilePricePromise]);

      const resolvedOwnershipMap = await Promise.all(ownershipMap.map(async (item) => {
        try { 
          const resolvedAddress : string = await library.lookupAddress(item[1]);
          return [item[0], item[1], resolvedAddress] as [BigNumber, string, string | null];
        } catch (error) {}

        return [item[0], item[1], null] as [BigNumber, string, string | null];
      }));

      setArt(svg);
      setRemainingBlocks(remainingBlocks);
      setRevisionCount(revisionCount);
      setOwnershipMap(resolvedOwnershipMap);
      setTilePrice(tilePrice);
    }

    if (active) {
      const contract : PopularPixels = PopularPixels__factory.connect(PUBLIC_PIIXELS_ADDRESS, library);
      const transferFilter           = contract.filters.Transfer(null, null, null);
      const revisionFilter           = contract.filters.Revision(null, null, null);
      const listener = (event : any) => {retrieveArt()};

      retrieveArt();

      contract.on(transferFilter, listener);
      contract.on(revisionFilter, listener);

      return () => {contract.off(transferFilter, listener); contract.off(revisionFilter, listener)}
    }
  }, [active, library]);


  return (
    <>
      <HeroSection svg={art} />
      <StatsSection ownershipMap={ownershipMap} remainingBlocks={remainingBlocks} revisionCount={revisionCount} />
      <EditorSection svg={art} ownershipMap={ownershipMap} tilePrice={tilePrice ?? BigNumber.from(0) }/>
      <HistorySection />
    </>
  );


}