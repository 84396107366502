import React from 'react';
import './App.css';
import { ethers } from 'ethers';
import AutoConnectingInjectedProvider from './util/AutoConnectingInjectedProvider';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import DynamicContent from './DynamicContent';
import FaqSection from './sections/FaqSection';


function getLibrary(provider: ethers.providers.ExternalProvider) {
  return new ethers.providers.Web3Provider(provider);
}

const NetworkWeb3ReactRoot = createWeb3ReactRoot("network");

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
    <NetworkWeb3ReactRoot getLibrary={getLibrary}>
      <AutoConnectingInjectedProvider>
        <main className="page-wrapper">
          <DynamicContent />
          <FaqSection />
        </main>
      </AutoConnectingInjectedProvider>
    </NetworkWeb3ReactRoot>
  </Web3ReactProvider>
  );
}

export default App;
