import React, {useEffect, useState} from 'react';
import { Col, Row } from 'react-bootstrap';
import Element from './elements/Element';
import ShapeToolbar from './ShapeToolbar';
import ShapeInspector from './ShapeInspector';
import SvgCanvas from './SvgCanvas';
import { Tools } from './util/Tools';
import useBus from 'use-bus';


interface SvgDesignerProps {
  backgroundUri : string | null;
  onSerialized : (serialized : string) => void;
}

export default function SvgDesigner(props : SvgDesignerProps) {

  const [elements, setElements]           = useState<Element[]>([]);
  const [activeElement, setActiveElement] = useState<number | null>(null);
  const [selectedTool, setSelectedTool]   = useState<Tools>(Tools.CURSOR);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress)
  
    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }  
  });

  useBus('MintButton/onMinted', () => {
    setElements([]);
  }, [elements]); 

  function onElementChanged(changedElementIndex : number, updated: Element) {
    setElements(elements.map((element, index) => {
      if (changedElementIndex === index)  {
        return updated;
      } else {
        return element;
      }
    }));
  }

  function onElementSelected(index : number) {
    console.log("onElementSelected: " +index);
    setActiveElement(index);
  }

  function onElementAdded(element : Element) {
    const newElementIndex = elements.length;

    setElements([...elements, element]);
    setSelectedTool(Tools.CURSOR);
    setActiveElement(newElementIndex);
  }

  function onActiveElementChanged(e: Element) {
    setElements(elements.map((element, index) => {
      if (activeElement != null && activeElement === index) {
        return e;
      } else {
        return element;
      }
    }));
  }

  function onBringActiveElementForward() {
    if (activeElement != null && activeElement < elements.length - 1) {
      let reorderedElements = elements.map((element) => element);
      let existingElement   = reorderedElements[activeElement+1];

      reorderedElements[activeElement+1] = reorderedElements[activeElement];
      reorderedElements[activeElement]   = existingElement;

      setElements(reorderedElements);
      setActiveElement(activeElement+1);
    }
  }

  function onSendActiveElementBackwards() {
    if (activeElement != null && activeElement > 0) {
      let reorderedElements = elements.map((element) => element);
      let existingElement   = reorderedElements[activeElement-1];


      reorderedElements[activeElement-1] = reorderedElements[activeElement];
      reorderedElements[activeElement]   = existingElement;

      setElements(reorderedElements);
      setActiveElement(activeElement-1);
    }
  }

  function handleKeyPress(event : KeyboardEvent) {      
    if (event.key === "Backspace" || event.key === "Delete") {
      if (activeElement != null && elements[activeElement].deletable) {
        setElements(elements.filter((element, index) => index !== activeElement));  
        setActiveElement(null);
      }  
    } else if (event.key === "Escape") {
      setActiveElement(null);
    }
  }

  return (
    <>
      <Row>
        <Col md="6" className="mb-3">
          <ShapeToolbar selectedTool={selectedTool} updateSelectedTool={setSelectedTool} />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <SvgCanvas elements={elements} 
                      currentTool={selectedTool}
                      backgroundUri={props.backgroundUri}
                      onElementChange={onElementChanged}
                      onElementAdded={onElementAdded}
                      onElementSelected={onElementSelected}
                      onSerialized={props.onSerialized}/>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          { activeElement != null && elements[activeElement] !== undefined && <ShapeInspector onBringForward={onBringActiveElementForward} onSendBackward={onSendActiveElementBackwards} onElementChanged={onActiveElementChanged} 
                                                      element={elements[activeElement]} />}
        </Col>
      </Row>
    </>
  );

}