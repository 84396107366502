import { BigNumber } from "ethers";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import MintButton from "../components/MintButton";
import OwnershipMap from "../components/OwnershipMap";
import SaveEditsButton from "../components/SaveEditsButton";
import SvgDesigner from "../editor/SvgDesigner";

interface EditorSectionProps {
  svg: string | null;
  ownershipMap: [BigNumber, string, string | null][];
  tilePrice: BigNumber;
}

export default function EditorSection(props: EditorSectionProps) {

  const [selectedTokenIds, setSelectedTokenIds] = useState<number[]>([]);
  const [showEditorModal, setShowEditorModal]   = useState<boolean>(false);
  const [editingTokenId, setEditingTokenId]     = useState<number|null>(null);
  const [serializedPixels, setSerializedPixels] = useState<string>("");

  function toggleTokenIdSelected(tokenId: number) {
    if (selectedTokenIds.findIndex((item) => item === tokenId) === -1) {
      setSelectedTokenIds([...selectedTokenIds, tokenId]);
    } else {
      setSelectedTokenIds(selectedTokenIds.filter((item) => item !== tokenId));
    }
  }

  function toggleEditorModal(tokenId: number) {
    setEditingTokenId(tokenId);
    setShowEditorModal(true);
  }

  return (
    <section id="mint" className="container mb-5 pt-lg-2 pt-xl-4 pb-2 pb-md-3 pb-lg-5">
      <h2 className="h1 mb-4 text-center">Get Started</h2>
      <p className="mb-4 mx-auto pb-3 fs-lg text-center" style={{maxWidth: "636px"}}>
        Mint NFTs by claiming pixels in the larger collective work, then edit them as many times as you'd like to help form the whole.
      </p>
      <Row>
        <Col lg={{span: 8, offset: 2}}>
          <div className="text-center">
            <OwnershipMap svg={props.svg} tilePrice={props.tilePrice} map={props.ownershipMap} selectedTokenIds={selectedTokenIds} toggleSelectedTokenId={toggleTokenIdSelected} togglePixelEditor={toggleEditorModal}/>        
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={{span: 8, offset: 2}}>
          <div className="text-center mt-3">
            <MintButton tokenIds={selectedTokenIds} onMinted={() => setSelectedTokenIds([])}/>      
          </div>

          <Modal show={showEditorModal} onHide={() => setShowEditorModal(false)} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>Edit your pixels</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SvgDesigner backgroundUri={null} onSerialized={(serialized) => {setSerializedPixels(serialized)}} />
            </Modal.Body>
            <Modal.Footer>
              <SaveEditsButton tokenId={editingTokenId} layer={serializedPixels} onSaveComplete={() => {setEditingTokenId(null); setShowEditorModal(false); }} />
              <Button variant="secondary" >
                Close without saving
              </Button>
            </Modal.Footer>
          </Modal>

        </Col>
      </Row>
    </section>
  );
}