import { useWeb3React } from "@web3-react/core";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useState } from 'react';
import ConnectWalletForm from "./ConnectWalletForm";
import { PUBLIC_PIIXELS_ADDRESS } from "../util/Constants";
import { BigNumber } from "ethers";
import { PopularPixels, PopularPixels__factory } from "../typechain";

interface MintButtonProps {
  tokenIds: number[];
  onMinted: () => void;
}

export default function MintButton(props: MintButtonProps) {

  const [showModal, setShowModal]                          = useState(false);
  const { active : signerActive, library : signerLibrary } = useWeb3React()
  const [isMinting, setIsMinting]                          = useState(false);
  const [mintingStatus, setMintingStatus]                  = useState("");

  async function onMint() {
    if (isMinting) return;
    setIsMinting(true);
    setMintingStatus("");

    try {
      const contract : PopularPixels = PopularPixels__factory.connect(PUBLIC_PIIXELS_ADDRESS, signerLibrary.getSigner());
      const price    : BigNumber     = await contract.getPrice();

      const result = await contract.mint(props.tokenIds, {value: price.mul(props.tokenIds.length)});
      await result.wait(1);

      props.onMinted();
    } catch (error) {
      console.error(error);
      setMintingStatus("Minting failed!");
    }

    setIsMinting(false);
  }

  return ( 
    <Row>
      <Col>
        <div className="d-grid gap-2">

        { mintingStatus !== "" && <h4 className="text-danger">{mintingStatus}</h4>}

        { signerActive ? <Button size="lg" variant="primary" onClick={onMint} disabled={props.tokenIds.length < 1}>{isMinting ?<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span>&nbsp;</span></> : <></>}{isMinting ? "Minting..." : props.tokenIds.length > 0 ? "Mint " + (props.tokenIds.length) + (props.tokenIds.length > 1 ? " tiles" : " tile") : "Select tiles to mint"}</Button> : 
                         <Button size="lg" variant="secondary" onClick={() => setShowModal(true)}>Connect wallet</Button>
        }
        </div>

        <Modal show={showModal && !signerActive} onHide={() => setShowModal(false)} aria-labelledby="contained-modal-title-vcenter" size="lg" centered>
          <Modal.Header closeButton>
            <Modal.Title>Connect wallet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ConnectWalletForm />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    </Row>
    )

}