import React, { useState, useEffect } from 'react';
import { getViewPortClickPosition } from '../util/ViewPort';
//import useKeyPress from '../util/useKeyPress';
import PolygonElement, { PolygonElementPoint, PolygonPointType } from './PolygonElement';

interface PolygonProps {
  element: PolygonElement;
  canvas: SVGSVGElement;
  onMouseOver: () => void;
  onSelected: () => void;
  onClosed: () => void;
  onUpdate: (element : PolygonElement) => void;
}

export default function Polygon(props : PolygonProps) {

  const [hoverNode, setHoverNode]         = useState<number | null>(null);
  const [draggingPoint, setDraggingPoint] = useState<[number, number] | null>(null);

  useEffect(() => {
    if (draggingPoint != null) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggingPoint]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress)
  
    return () => {
      document.removeEventListener('keyup', handleKeyPress)
    }  
  });

  const instructions = [
    `M ${props.element.points[0].x} ${props.element.points[0].y}`,
    props.element.points.slice(1).map((point) => {
      if      (point.type === PolygonPointType.LINE)  return `L ${point.x} ${point.y}`;
      else if (point.type === PolygonPointType.CURVE) return `C ${cx1(point)} ${cy1(point)} ${cx2(point)} ${cy2(point)} ${point.x} ${point.y}`;
      else     throw Error("assertion");
    }),
    props.element.closed ? 'Z' : ""
  ];

  function onPointMouseDown(event: React.MouseEvent, index: number, subIndex: number) {
    event.stopPropagation();

    if (!props.element.closed) {
      props.onClosed();
      return;
    }

    props.onUpdate(props.element.withSelectedPoint(index));
    setDraggingPoint([index, subIndex]);
  }

  function handleMouseMove(event: MouseEvent) {
    const [x, y] = getViewPortClickPosition(props.canvas, event);

    const points : PolygonElementPoint[] = props.element.points.map((point, index) => {
      if (draggingPoint != null && index === draggingPoint[0]) {
        if      (draggingPoint[1] === 0) return {...point, x: x, y: y};
        else if (draggingPoint[1] === 1) return {...point, cx1: x, cy1: y};
        else if (draggingPoint[1] === 2) return {...point, cx2: x, cy2: y};
        else                             throw Error("Unknown point");
      } else {
        return point;
      }
    });

    props.onUpdate(props.element.withPoints(points));
  }

  function handleMouseUp(event: MouseEvent) {
    setDraggingPoint(null);
  }

  function handleKeyPress(event: KeyboardEvent) {
    if (event.key === "Delete" || event.key === "Backspace") {
      if (props.element.editing && props.element.selectedPoint !== -1) {
        props.onUpdate(props.element.withPoints(props.element.points.filter((point) => !point.selected)));
      }
    }
  }

  function cx1(point : PolygonElementPoint) {
    return point.cx1 ?? point.x.valueOf() - 10;
  }

  function cy1(point : PolygonElementPoint) {
    return point.cy1 ?? point.y.valueOf();
  }

  function cx2(point : PolygonElementPoint) {
    return point.cx2 ?? point.x.valueOf() + 10;
  }

  function cy2(point : PolygonElementPoint) {
    return point.cy2 ?? point.y.valueOf();
  }

  function renderEditingItems() {
    const width = 2;
    let points : PolygonElementPoint[];

    if      (!props.element.editing && props.element.closed)  points = [];
    else if (props.element.editing && props.element.closed)   points = props.element.points;
    else if (!props.element.editing && !props.element.closed) points = props.element.points.slice(0, 1);
    else                                                      points = props.element.points.slice(0, -1);

    return [...points.map((point, index) => {
              return <ellipse key={`point-${index}`}
                              cx={point.x.valueOf()} 
                              cy={point.y.valueOf()} 
                              rx={width / 2} 
                              ry={width / 2}
                              fill={"#ffffff"}
                              stroke={(props.element.closed && hoverNode != null && hoverNode === index) || point.selected ? "#ffd760" :"#555"}
                              strokeWidth={"1px"}
                              onMouseOver={() => setHoverNode(index)}
                              onMouseOut={() => setHoverNode(null)}
                              onMouseDown={(event) => onPointMouseDown(event, index, 0)}
                      />  
            }),
            points.map((point, index, source) => {
              if (point.type !== PolygonPointType.CURVE) {
                return <></>
              }

              return (
                <g>
                  <line key={`entry-line-${index}`} strokeWidth="1px" stroke="#888" strokeDasharray="5 5" x1={point.x.valueOf()} y1={point.y.valueOf()} x2={cx2(point).valueOf()} y2={cy2(point).valueOf()}/>
                  <line key={`exit-line-${index}`} strokeWidth="1px" stroke="#888" strokeDasharray="5 5" x1={source[index-1].x.valueOf()} y1={source[index-1].y.valueOf()} x2={cx1(point).valueOf()} y2={cy1(point).valueOf()}/>
                  <ellipse    key={`anchor-1-${index}`}
                              cx={cx1(point).valueOf()} 
                              cy={cy1(point).valueOf()} 
                              rx={width / 2} 
                              ry={width / 2}
                              fill={"#3381ff"}
                              stroke={"#555"}
                              strokeWidth={"1px"}
                              onMouseDown={(event) => onPointMouseDown(event, index, 1)}
                      />
                  <ellipse    key={`anchor-2-${index}`}
                              cx={cx2(point).valueOf()} 
                              cy={cy2(point).valueOf()} 
                              rx={width / 2} 
                              ry={width / 2}
                              fill={"#3381ff"}
                              stroke={"#555"}
                              strokeWidth={"1px"}
                              onMouseDown={(event) => onPointMouseDown(event, index, 2)}
                      />    
                </g>
              )
            })];
  }

  const centerX = (props.element.width.valueOf() / 2) + props.element.x.valueOf();
  const centerY = (props.element.height.valueOf() / 2) + props.element.y.valueOf();

  return (
    <g transform={"rotate(" + props.element.rotation + " " + centerX + " " + centerY + ")"}>
      <path   key={`path`}
              d={instructions.join(" ")}
              fill={props.element.fillColor}
              stroke={props.element.strokeColor}
              strokeWidth={props.element.strokeWidth.valueOf()}
              onMouseOver={() => {props.onMouseOver()}}
              onMouseDown={() => { if (props.element.closed) props.onSelected()}}
            />

      {
        renderEditingItems()
      }
      </g>
  );

}
