import { useWeb3React } from "@web3-react/core";
import { Button } from "react-bootstrap";
import { useState } from 'react';
import { PUBLIC_PIIXELS_ADDRESS } from "../util/Constants";
import { PopularPixels, PopularPixels__factory } from "../typechain";
import { BigNumber } from "ethers";
import { toUtf8Bytes } from "ethers/lib/utils";

interface SaveEditsButtonProps {
  tokenId: number | null;
  layer: string;
  onSaveComplete: () => void;
}

export default function SaveEditsButton(props: SaveEditsButtonProps) {

  const { active : signerActive, library : signerLibrary } = useWeb3React()
  const [isSaving, setIsSaving]                            = useState(false);

  async function onSave() {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const contract : PopularPixels = PopularPixels__factory.connect(PUBLIC_PIIXELS_ADDRESS, signerLibrary.getSigner());

      const result = await contract.save(BigNumber.from(props.tokenId), toUtf8Bytes(props.layer));
      await result.wait(1);

      setIsSaving(false);
      props.onSaveComplete();  
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  }

  return ( 
         signerActive ? <Button variant="primary" onClick={onSave} disabled={props.tokenId == null}>{isSaving ?<><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span>&nbsp;</span></> : <></>}{isSaving ? "Saving..." : "Save"}</Button> : 
                        <Button variant="secondary" disabled={true}>Wallet disconnected</Button>
        
    )

}