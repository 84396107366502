import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useEthNetwork } from "../NetworkConnector";
import { PopularPixels, PopularPixels__factory } from "../typechain";
import { PUBLIC_PIIXELS_ADDRESS } from "../util/Constants";

import HistoryLog from "../components/HistoryLog";
import HistoricalCanvasCard from "../components/HistoricalCanvasCard";

export interface HistoryEvent {
  author : string;
  resolvedAuthor: string | null;
  tokenId : BigNumber;
  data: string;
  key: string;
  blockHeight: number;
}

export default function HistorySection() {

  const [active, library]                             = useEthNetwork();
  const [revisionLogs, setRevisionLogs]               = useState<HistoryEvent[]>([]);
  const [revisionLogsByToken, setRevisionLogsByToken] = useState<Map<number, Array<HistoryEvent>>>(new Map<number, Array<HistoryEvent>>());
  const [historicalSvgs, setHistoricalSvgs]           = useState<string[]>([]);

  useEffect(() => {
    async function retrieveHistory() {
      const contract : PopularPixels   = PopularPixels__factory.connect(PUBLIC_PIIXELS_ADDRESS, library);
      const revisionFilter             = contract.filters.Revision(null, null, null);
      const eventLogs : HistoryEvent[] = await Promise.all((await contract.queryFilter(revisionFilter)).map(async (event) => {
        let resolvedName : string | null = null;

        try {
          resolvedName = await library.lookupAddress(event.args[0]);
        } catch (error) {
        }

        return {author: event.args[0], resolvedAuthor: resolvedName, tokenId: event.args[1], data: event.args[2], key: event.transactionHash + "-" + event.logIndex, blockHeight: event.blockNumber};
      }));

      const eventLogsByToken         = eventLogs.reduce((eventMapByToken, event) => {
        const tokenId = event.tokenId.toNumber();

        let tokenHistory = eventMapByToken.get(tokenId);

        if (tokenHistory == null) {
          tokenHistory = new Array<HistoryEvent>();
        }

        tokenHistory.push(event);
        eventMapByToken.set(tokenId, tokenHistory);

        return eventMapByToken;
      }, new Map<number, Array<HistoryEvent>>());


      setRevisionLogs(eventLogs.reverse());
      setRevisionLogsByToken(eventLogsByToken);
    }
  
    if (active) {
      const contract : PopularPixels = PopularPixels__factory.connect(PUBLIC_PIIXELS_ADDRESS, library);
      const revisionFilter           = contract.filters.Revision(null, null, null);
      const listener = (event : any) => {retrieveHistory()};

      contract.on(revisionFilter, listener);

      retrieveHistory();

      return () => {contract.off(revisionFilter, listener)};
    }
  }, [active, library]);

  useEffect(() => {
    async function retrieveHistoricalImages() {
      const contract : PopularPixels = PopularPixels__factory.connect(PUBLIC_PIIXELS_ADDRESS, library);

      const historialSvgs = await Promise.all(revisionLogs.map(async (event) => {
        return await contract.getEncodedSvgUri({blockTag: event.blockHeight});
      }));

      setHistoricalSvgs(historialSvgs.reverse());
    }

    if (active && revisionLogs.length > 0) retrieveHistoricalImages();
  }, [revisionLogs, active, library])

  return (
    <div className="bg-secondary pb-5 pt-5">
      <section className="container mb-5" id="benefits">
        <h2 className="h1 mb-4 pb-3 text-center">Explore the history</h2>
        <Row>
          <Col lg={{span: 7}} className="mb-2">
            <HistoricalCanvasCard svgs={historicalSvgs} />
          </Col>
          <Col lg="5">
            <HistoryLog revisionLogs={revisionLogs} revisionLogsByToken={revisionLogsByToken} />
          </Col>
        </Row>
      </section>
    </div>
  );
}