import { toUtf8String } from "ethers/lib/utils";
import { Card } from "react-bootstrap";

import rightArrow from "../assets/img/right-arrow.svg";
import {List} from 'react-content-loader';
import { HistoryEvent } from "../sections/HistorySection";

interface HistoryLogProps {
  revisionLogs: HistoryEvent[];
  revisionLogsByToken: Map<number, Array<HistoryEvent>>;
}

export default function HistoryLog(props: HistoryLogProps) {  

  function layerFor(event: HistoryEvent | null) {
    if (event == null) return null;
    else               return toUtf8String(event.data);
  }

  function makeSvgUriFor(serialized: string | null) {
    if (serialized == null) {
      return "data:image/svg+xml;base64," + btoa('<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" style="background-size:auto;background-image:url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0iI2ZmZiI+PC9yZWN0Pgo8cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNGN0Y3RjciPjwvcmVjdD4KPHJlY3QgeD0iMTAiIHk9IjEwIiB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9IiNGN0Y3RjciPjwvcmVjdD4KPC9zdmc+"></svg>');
    } else {
      return "data:image/svg+xml;base64," + btoa('<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">' + serialized + "</svg>");
    }
  }

  function findPreviousImage(event: HistoryEvent) : HistoryEvent | null {
    const tokenLogs    = props.revisionLogsByToken.get(event.tokenId.toNumber());
    const currentIndex = tokenLogs?.findIndex((item) => item.key === event.key) ?? -1;

    if (currentIndex < 1) return null;

    return tokenLogs![currentIndex-1];
  }
  return (
    <>
      <div style={props.revisionLogs.length !== 0 ? {display: "none"} : {display: "block"}}>
        <div style={{maxHeight: "700px", overflowY: "scroll"}}>
        {
          Array.from(Array(10).keys()).map((item) => {
            return (
              <Card className="mb-3" key={"placeholder-" + item}>
                <Card.Body>
                  <List />
                </Card.Body>
              </Card>
            );
          })
        }        
        </div>
      </div>

      <div style={props.revisionLogs.length !== 0 ? {display: "block"} : {display: "none"}}>
        <div style={{maxHeight: "700px", overflowY: "scroll"}}>
                {
                  props.revisionLogs.map((item, index) => {
                    return (
                      <Card className="mb-3" key={item.key}>
                        <Card.Body>
                          <div className="d-flex align-items-center mb-3 text-truncate">
                            <span className="badge fs-sm text-nav bg-secondary text-decoration-none">{item.tokenId.toNumber()}</span>
                            <span className="fs-sm text-muted border-start ps-3 ms-3">{item.resolvedAuthor ?? item.author}</span>
                          </div>
                          <hr className="my-4"></hr>
                          <div>
                            <img width="50px" height="50px" src={makeSvgUriFor(layerFor(findPreviousImage(item)))} alt="Before" />
                            <img src={rightArrow} alt="Right arrow" />
                            <img width="50px" height="50px" src={makeSvgUriFor(layerFor(item))} alt="After" />
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })
                }
        </div>
      </div>
    </>
  );

}