import { ListGroup } from "react-bootstrap";
import { Tools } from "./util/Tools";

interface ShapeToolbarProps {
  selectedTool : Tools;
  updateSelectedTool : (selected : Tools) => void;
}


export default function ShapeToolbar(props : ShapeToolbarProps) {

  return (
    <ListGroup horizontal>
      <ListGroup.Item className="text-center" onClick={() => props.updateSelectedTool(Tools.CURSOR)} action variant={props.selectedTool === Tools.CURSOR ? "secondary" : "light"}>
        <svg viewBox="0 0 24 24"
              preserveAspectRatio="xMidYMid meet"
              style={{fill:"#b5b5b5", verticalAlign:"middle", width:"30", height:"30"}}>

          <polygon fill="#b5b5b5" points="8.2,20.9 8.2,4.9 19.8,16.5 13,16.5 12.6,16.6 "/>
          <polygon fill="#b5b5b5" points="17.3,21.6 13.7,23.1 9,12 12.7,10.5 "/>
          <rect x="12.5" y="13.6" transform="matrix(0.9221 -0.3871 0.3871 0.9221 -5.7605 6.5909)" width="2" height="8"/>
          <polygon points="9.2,7.3 9.2,18.5 12.2,15.6 12.6,15.5 17.4,15.5 "/>

        </svg>
      </ListGroup.Item>

      <ListGroup.Item className="text-center" onClick={() => props.updateSelectedTool(Tools.RECTANGLE)} action variant={props.selectedTool === Tools.RECTANGLE ? "secondary" : "light"}>
        <svg viewBox="0 0 24 24"
              onClick={() => console.log("onClick")}
              preserveAspectRatio="xMidYMid meet"
              style={{fill:"#b5b5b5", verticalAlign:"middle", width:"30", height:"30"}}>
              <rect width={14} height={14} x={4} y={5} rx={3} ry={3} />
        </svg>
      </ListGroup.Item>

      <ListGroup.Item className="text-center" onClick={() => props.updateSelectedTool(Tools.CIRCLE)} action variant={props.selectedTool === Tools.CIRCLE ? "secondary" : "light"}>
        <svg viewBox="0 0 24 24"
              onClick={() => console.log("onClick")}
              preserveAspectRatio="xMidYMid meet"
              style={{fill:"#b5b5b5", verticalAlign:"middle", width:"30", height:"30"}}>
             <circle r={8} cx={11} cy={12} />
        </svg>
      </ListGroup.Item>


      <ListGroup.Item className="text-center" onClick={() => props.updateSelectedTool(Tools.POLYGON)} action variant={props.selectedTool === Tools.POLYGON ? "secondary" : "light"}>
        <svg viewBox="0 0 24 24"
              onClick={() => console.log("onClick")}
              preserveAspectRatio="xMidYMid meet"
              style={{fill:"#b5b5b5", verticalAlign:"middle", width:"30", height:"30"}}>
          <g transform={"scale(0.034) translate(100 100)"}>
            <path d="M477.043,219.205L378.575,48.677c-7.974-13.802-22.683-22.292-38.607-22.292H143.041c-15.923,
                    0-30.628,8.49-38.608,22.292 L5.971,219.205c-7.961,13.801-7.961,30.785,0,44.588l98.462,
                    170.543c7.98,13.802,22.685,22.293,38.608,22.293h196.926 c15.925,0,30.634-8.491,
                    38.607-22.293l98.469-170.543C485.003,249.99,485.003,233.006,477.043,219.205z"/>
          </g>
        </svg>
      </ListGroup.Item>

    </ListGroup>

  );
}