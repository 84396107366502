import { useState } from "react";
import { Card, Image } from "react-bootstrap";
import ContentLoader from "react-content-loader";

interface CanvasCardProps {
  svg: string | null;
}

export default function CanvasCard(props: CanvasCardProps) {

  const [isImageLoaded, setImageLoaded] = useState("");

  return (
    <Card>
      <Card.Body>
          <div style={isImageLoaded === props.svg && isImageLoaded !== "" ? {display: "none"} : {display: "block"}}>
            <ContentLoader viewBox="0 0 500 500">
              <rect x="0" y="0" rx="0" ry="10" width="100%" height="100%" />
            </ContentLoader>
          </div>

          <div style={isImageLoaded === props.svg && isImageLoaded !== "" ? {display: "block"} : {display: "none"}}>
            <Image src={props.svg ?? ""} fluid={true} alt="Collective Canvas" onLoad={() => {console.log("onloaded"); setImageLoaded(props.svg ?? "")}} />
          </div>

      </Card.Body>
    </Card>
  );

}

