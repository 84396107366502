import { Card, Col, Image, Row } from "react-bootstrap";
import terrain from "../assets/img/terrain.svg";
import team from "../assets/img/groups.svg";
import edits from "../assets/img/edits.svg";
import { useEffect, useState } from "react";
import { BigNumber } from "ethers";
import ContentLoader from "react-content-loader";

interface StatsSectionProps {
  remainingBlocks: BigNumber | null;
  revisionCount: BigNumber | null;
  ownershipMap: [BigNumber, string, string | null][];
}

export default function StatsSection(props: StatsSectionProps) {

  const [ownerCount, setOwnerCount] = useState<number|null>(null);

  useEffect(() => {
    setOwnerCount(new Set(props.ownershipMap.map((tuple) => tuple[1])).size);
  }, [props.ownershipMap]);

  return (
    <section className="container mb-5" id="benefits">
      <Row>
        <Col md="4" className="mb-3">
          <Card>
            <Card.Body className="text-center">
              <Image src={terrain} />
              <div style={props.remainingBlocks != null ? {display: "none"} : {display: "block"}}>
                <ContentLoader viewBox="0 0 100 50" width={250} height={25}>
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </div>

              <div style={props.remainingBlocks != null ? {display: "block"} : {display: "none"}}>
                <h4>{props.remainingBlocks?.toString() ?? ""}</h4>
              </div>
              <p className="text-center">pixel blocks remaining</p>
            </Card.Body>
          </Card>
        </Col>

        <Col md="4" className="mb-3">
          <Card>
            <Card.Body className="text-center">
              <Image src={team} />
              <div style={ownerCount != null && ownerCount !== 0 ? {display: "none"} : {display: "block"}}>
                <ContentLoader viewBox="0 0 100 50" width={250} height={25}>
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </div>

              <div style={ownerCount != null && ownerCount !== 0 ? {display: "block"} : {display: "none"}}>
                <h4>{ownerCount ?? ""}</h4>
              </div>
              <p className="text-center">artists contributing</p>
            </Card.Body>
          </Card>
        </Col>

        <Col md="4" className="mb-3">
          <Card>
            <Card.Body className="text-center">
              <Image src={edits} />
              <div style={props.revisionCount != null ? {display: "none"} : {display: "block"}}>
                <ContentLoader viewBox="0 0 100 50" width={250} height={25}>
                  <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
                </ContentLoader>
              </div>

              <div style={props.revisionCount != null ? {display: "block"} : {display: "none"}}>
                <h4>{props.revisionCount?.toString() ?? ""}</h4>
              </div>
              <p className="text-center">revisions so far</p>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </section>
  );
}