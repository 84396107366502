import heroBg from '../assets/img/hero-bg.svg';
import CanvasCard from "../cards/CanvasCard";

interface HeroSectionProps {
  svg: string | null;
}

export default function HeroSection(props: HeroSectionProps) {

  return (
    <section style={{backgroundImage: `url(${heroBg})`}} className="jarallax position-relative d-flex align-items-center min-vh-100 bg-light mb-5 py-lg-5 pt-5"  data-jarallax data-img-position="0% 100%" data-speed="0.5">
    <div className="container position-relative zindex-5 py-5">
      <div className="row justify-content-md-start justify-content-center">
        <div className="col-md-6 ps-md-5 col-sm-8 order-md-1 order-2 d-flex flex-column justify-content-between mt-4 pt-2 text-md-start text-center">
          <div className="mb-md-5 pb-xl-5 mb-4">


            <h1 className="display-2 mb-md-5 mb-3 pb-3">
              Welcome to <span className="text-gradient-primary">Collective Art</span>
            </h1>
            
            <div className="align-items-md-start">
              <p className="mb-2 ps-md-3 text-muted">An NFT that is collectively owned and collectively created.</p>
              <p className="mb-md-3 mb-0 ps-md-3 text-muted">Art that constantly evolves.</p>

              <a href="#mint" className="btn btn-lg btn-primary flex-shrink-0 me-md-4 mb-md-0 mb-sm-4 mb-3 ms-md-3 mt-3">Get your pixels</a>
            </div>
          </div>

        </div>

        <div className="pe-md-5 col-md-6 col-sm-6 col-9 order-md-2 order-1">
          <CanvasCard svg={props.svg} />
        </div>
      </div>
    </div>
  </section>
  );
}