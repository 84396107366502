import { BigNumber } from "ethers";
import React, { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import ReactDOMServer from 'react-dom/server';
import { useWeb3React } from "@web3-react/core";
import ContentLoader from "react-content-loader";
import { formatEther } from "ethers/lib/utils";

interface OwnershipMapProps {
  svg: string | null;
  map: [BigNumber, string, string | null][];
  tilePrice: BigNumber;

  toggleSelectedTokenId: (tokenId: number) => void;
  togglePixelEditor: (tokenId: number) => void;
  selectedTokenIds: number[];
}

interface Tooltip {
  tokenId: number;
  text: string[];
}

export default function OwnershipMap(props: OwnershipMapProps) {

  const svgRef                            = useRef<SVGSVGElement|null>(null);
  const [ownershipMap, setOwnershipMap]   = useState<Map<number, [string | null, string]>>(new Map<number, [string | null, string]>());
  const [hoverTile, setHoverTile]         = useState<number|null>(null);
  const [tooltip, setTooltip]             = useState<Tooltip|null>(null);
  const [accounts, setAccounts]           = useState<string[]>([]);

  const { active, library } = useWeb3React()

  useEffect(() => {
    setOwnershipMap(new Map(props.map.map(([tokenId, owner, resolvedOwner]) => [tokenId.toNumber(), [resolvedOwner, owner]])));
  }, [props.map]);

  useEffect(() => {
    async function updateAccountInfo() {
      setAccounts(await library.listAccounts());
    }

    if (active) updateAccountInfo();
  }, [active, library])

  function onMouseEnterTile(tokenId: number) {
    const owner = ownershipMap.get(tokenId);

    setHoverTile(tokenId);
    setTooltip({tokenId: tokenId, 
                text: owner == null ? ["Status: Available", "Token ID: " + tokenId, `Price: ${formatEther(props.tilePrice)} eth`] : 
                accounts.findIndex((account) => account === owner[1]) !== -1 ? ["Status: Your pixels!", "Token ID: " + tokenId] : ["Status: Claimed", "Token ID: " + tokenId, "Owner: " + (owner[0] ?? owner[1])]});

  }

  function onMouseExitTile(tokenId: number) {
    if (hoverTile === tokenId) {
      setHoverTile(null);
      setTooltip(null);
    }
  }

  function onMouseClick(tokenId: number) {
    console.log("onClick");

    if (ownershipMap.get(tokenId) == null) {
      console.log("Not owned");
      props.toggleSelectedTokenId(tokenId);
    } else if (accounts.findIndex((account) => account === ownershipMap.get(tokenId)?.[1]) !== -1) {
      console.log("Toggling pixel editor");
      props.togglePixelEditor(tokenId);
    }
  }

  return (
    <>
      <div style={props.svg != null ? {display: "none"} : {display: "block"}}>
        <ContentLoader viewBox="0 0 1000 1000">
          <rect x="0" y="0" rx="0" ry="10" width="100%" height="100%" />
        </ContentLoader>
      </div>

      <div style={props.svg != null ? {display: "block"} : {display: "none"}}>
        <svg viewBox="0 0 1000 1000" 
              xmlns="http://www.w3.org/2000/svg" 
              ref={svgRef}
              data-tip={"i"}
              style={{backgroundSize:"contain", backgroundImage:"url(" + props.svg + ")"}}>
          {
            Array.from(Array(400).keys())
                  .map((tokenId) => {
                  return (
                    <g key={tokenId} transform={`translate(${(tokenId * 50) % 1000} ${Math.floor((tokenId * 50) / 1000) * 50})`}>
                      <rect width="49" height="49" 
                            stroke={ownershipMap.get(tokenId) == null ? 
                              tooltip != null && tooltip.tokenId === tokenId ? "#5ED48F" : "#2EAB62" : 
                              accounts.findIndex((account) => ownershipMap.get(tokenId)?.[1] === account) === -1 ? "#aaaaaaaa" : "blue"} 
                            strokeWidth={tooltip != null && tooltip.tokenId === tokenId ? "2px" : "1px"}
                            fill="#00000000"
                            onClick={() => onMouseClick(tokenId)}
                            onMouseEnter={() => onMouseEnterTile(tokenId)}
                            onMouseLeave={() => onMouseExitTile(tokenId)} />

                      {
                        props.selectedTokenIds.findIndex((item) => item === tokenId) !== -1 && <svg xmlns="http://www.w3.org/2000/svg" x="12" y="12" height="24px" viewBox="0 0 24 24" width="24px" fill="none" onClick={() => onMouseClick(tokenId)}>
                                                                                        <g clipPath="url(#B)" fill="url(#A)">
                                                                                          <path d="M0 0h24v24H0z" fill="none"/>
                                                                                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                                                                                        </g>
                                                                                        <defs>
                                                                                          <linearGradient id="A" x1="0" y1="24.115" x2="48" y2="24.115" gradientUnits="userSpaceOnUse">
                                                                                            <stop stopColor="#6366f1"/>
                                                                                            <stop offset=".5" stopColor="#8b5cf6"/>
                                                                                            <stop offset="1" stopColor="#d946ef"/>
                                                                                          </linearGradient>
                                                                                          <clipPath id="B">
                                                                                            <path fill="#fff" d="M0 0h48v48H0z"/>
                                                                                          </clipPath>
                                                                                        </defs>
                                                                                      </svg>
                      }
                    </g>
                  );
                })
          }
          

        </svg>
        <ReactTooltip html={true} 
                      multiline={true} 
                      getContent={() => tooltip != null ? ReactDOMServer.renderToStaticMarkup(<>{tooltip.text.map((line) =>  <>{line}<br /></>)}</>) : ""} />
      </div>
    </>
  );


}