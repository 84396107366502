import { Card, Col, Row } from "react-bootstrap";

export default function FaqSection() {

  return (
    <section className="container mb-5 pt-2 pb-3 py-md-4 py-lg-5">
      <h2 className="h1 pb-2 pb-lg-0 mb-4 mb-lg-5 text-center">FAQ</h2>
      <Row>
        <Col md={{offset: 1, span: 5}}>
          <Card className="card border-0 shadow-sm pt-4 mb-4">
            <Card.Header>
              <h6>What is this?</h6>
            </Card.Header>
            <Card.Body>
              <p>Popular Pixels is a collective art project that is in constant evolution. It is a single graphic composed of many pixel block NFTs, each of which can be claimed and edited by their owners.</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md="5">
          <Card className="card border-0 shadow-sm pt-4 mb-4">
            <Card.Header>
              <h6>How many edits can I make to my pixel blocks?</h6>
            </Card.Header>
            <Card.Body>
              <p>There is no limit, Popular Pixels is in constant evolution.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={{offset: 1, span: 5}}>
          <Card className="card border-0 shadow-sm pt-4 mb-4">
            <Card.Header>
              <h6>What does it cost to make edits?</h6>
            </Card.Header>
            <Card.Body>
              <p>You own your pixel block, there is no price (other than gas) for making edits.</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md="5">
          <Card className="card border-0 shadow-sm pt-4 mb-4">
            <Card.Header>
              <h6>Can I sell a pixel block at some point in the future?</h6>
            </Card.Header>
            <Card.Body>
              <p>Each pixel block is an NFT, so you can sell or exchange them on NFT marketplaces.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={{offset: 1, span: 5}}>
          <Card className="card border-0 shadow-sm pt-4 mb-4">
            <Card.Header>
              <h6>If I buy a pixel block that I didn't mint, can I edit it?</h6>
            </Card.Header>
            <Card.Body>
              <p>Yes, the current owner of a pixel block is the only person who can make changes to it.</p>
            </Card.Body>
          </Card>
        </Col>
        <Col md="5">
          <Card className="card border-0 shadow-sm pt-4 mb-4">
            <Card.Header>
              <h6>How are my pixels stored?</h6>
            </Card.Header>
            <Card.Body>
              <p>The entire NFT is stored on chain.</p>
            </Card.Body>
          </Card>
        </Col>

      </Row>

    </section>
  );


};