import { useState } from "react";
import { Button, Card, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import playButton from '../assets/img/play.svg';
import pauseButton from '../assets/img/pause.svg';
import useInterval from '../util/useInterval';
import ContentLoader from "react-content-loader";

interface HistoricalCanvasCardProps {
  svgs: string[];
}

export default function HistoricalCanvasCard(props: HistoricalCanvasCardProps) {

  const [selectedLayer, setSelectedLayer]         = useState<number|null>(null);
  const [isPlaying, setIsPlaying]                 = useState<boolean>(false);

  useInterval(() => {
    setSelectedLayer(((selectedLayer ?? props.svgs.length - 1) + 1) % props.svgs.length);
  }, isPlaying ? 250 : null);

  return (
    <Card>
      <Card.Body>
        <div style={props.svgs.length > 0 ? {display: "none"} : {display: "block"}}>
          <ContentLoader viewBox="0 0 1000 1000">
            <rect x="0" y="0" rx="0" ry="10" width="100%" height="100%" />
          </ContentLoader>
        </div>

        <div style={props.svgs.length > 0 ? {display: "block"} : {display: "none"}}>
          <Image src={props.svgs.length > 0 ? props.svgs[selectedLayer ?? props.svgs.length - 1] : ""} fluid={true} alt="Historical Canvas" />

          <div className="mt-2" style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
            <Button className='me-2' variant='outline-secondary' onClick={() => {setIsPlaying(!isPlaying);}}>{isPlaying ? <img src={pauseButton} alt='Play' /> : <img src={playButton} alt='Pause' />}</Button>
            {props.svgs.length > 0 && <OverlayTrigger defaultShow={true}
                            placement="right"
                            overlay={<Tooltip>{selectedLayer ?? props.svgs.length - 1}</Tooltip>
                          }>
              <input type="range" 
                    min={0}
                    max={props.svgs.length - 1}
                    value={selectedLayer ?? props.svgs.length - 1}
                    className="form-range"
                    onChange={(event) => {setSelectedLayer(Number.parseFloat(event.currentTarget.value))}} />
            </OverlayTrigger>
            }
          </div>
        </div>
      </Card.Body>
    </Card>
  );

}

