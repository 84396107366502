import { Row, Col } from 'react-bootstrap';
import { useWeb3React } from "@web3-react/core"
import { injected, walletconnect as walletConnect } from "../connectors"

import metamask from '../assets/logos/metamask.svg';
import walletconnect from '../assets/logos/walletconnect.svg';
import coinbase from '../assets/logos/coinbase.png';

import { useEffect, useState } from 'react';
//import ledger from '../assets/logos/ledger.png';

enum ConnectionType {
  METAMASK, WALLET_CONNECT, LEDGER, COINBASE
}

export default function ConnectWalletForm() {

  const { active, activate } = useWeb3React()

  const [connectingMetamask, setConnectingMetamask]           = useState(false);
  const [connectingWalletConnect, setConnectingWalletConnect] = useState(false);
  const [connectingLedger, setConnectingLedger]               = useState(false);
  const [connectingCoinbase, setConnectingCoinbase]           = useState(false);
  const [errorStatus, setErrorStatus]                         = useState('');

  useEffect(() => {
    if (active) {
      setConnectingMetamask(false);
      setConnectingWalletConnect(false);
      setConnectingLedger(false);
      setConnectingCoinbase(false);
      setErrorStatus('');
    }
  }, [active]);

  async function handleConnect(type : ConnectionType) {
    if (connectingMetamask || connectingWalletConnect || connectingLedger || connectingCoinbase) return;

    setErrorStatus('');

    let provider;

    switch (type) {
      case ConnectionType.METAMASK:       
        setConnectingMetamask(true); 
        provider = injected;
        break;
      case ConnectionType.WALLET_CONNECT: 
        setConnectingWalletConnect(true); 
        provider = walletConnect;
        break;
      case ConnectionType.LEDGER:         
        setConnectingLedger(true); 
        provider = walletConnect;
        break;
      case ConnectionType.COINBASE:
        setConnectingCoinbase(true);
        provider = injected;
        break;
      default:
        console.log("Assertion failed");
        return;
    }

    console.log("Provider is now: " + provider);

    await activate(provider, (error: Error) => {
      console.error(error);
      setErrorStatus(error.message);
      setConnectingMetamask(false);
      setConnectingWalletConnect(false);
      setConnectingLedger(false);
      setConnectingCoinbase(false);
    });
  };

  function renderConnectingStatus(connecting : boolean) {
    if (connecting) return (<div className="spinner-border" role="status"/>);
    else            return (<></>);
  }

  function renderErrorStatus() {
    if (errorStatus !== '') {
      return (<div className="alert alert-danger" role="alert">{errorStatus}</div>);
    } else {
      return (<></>);
    }
  }

  return (
    <>
      <Row>
        <Col className="text-center" sm={4}>
          <div role="button" onClick={() => {handleConnect(ConnectionType.COINBASE);}} className="text-decoration-none">
            <img src={coinbase} alt="MetaMask" style={{width: '45px', height: '45px'}}/>
            <h6>Coinbase Wallet</h6>
            <p className="text-muted">Connect with Coinbase Wallet extension</p>
            {renderConnectingStatus(connectingCoinbase)}
          </div>
        </Col>
        <Col className="text-center" sm={4}>
          <div role="button" onClick={() => {handleConnect(ConnectionType.METAMASK);}} className="text-decoration-none">
            <img src={metamask} alt="MetaMask" style={{width: '45px', height: '45px'}}/>
            <h6>MetaMask</h6>
            <p className="text-muted">Connect your Metamask wallet</p>
            {renderConnectingStatus(connectingMetamask)}
          </div>
        </Col>
        <Col className="text-center" sm={4}>
          <div role="button" onClick={() => {handleConnect(ConnectionType.WALLET_CONNECT);}} className="text-decoration-none">
            <img src={walletconnect} alt="Wallet Connect" style={{width: '45px', height: '45px'}}/>
            <h6>Wallet Connect</h6>
            <span className="text-muted">Scan with WalletConnect to connect</span>
            {renderConnectingStatus(connectingWalletConnect)}
          </div>
        </Col>
        { /*
        <Col className="text-center" sm={4}>
          <img src={ledger} alt="Ledger" style={{width: '45px', height: '45px'}}/>
          <h3>Ledger</h3>
          <p className="text-muted">Connect your Ledger wallet</p>
          {renderConnectingStatus(connectingLedger)}
        </Col>
        */ }
      </Row>

      {renderErrorStatus()}
    </>);  
}